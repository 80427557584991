.nav {
  background: #e8f2f8;
  text-align: center;

  &__links {
    &__close {
      display: none;
      position: fixed;
      line-height: 70px;
      font-size: 32px;
      right: 5%;
      top: 30px;
    }

    a {
      display: inline;
      margin: 0 20px;
      line-height: 52px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      color: #838a8e;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 1.2px;
    }
  }

  &__burger {
    display: none;
    line-height: 40px;
    font-size: 16px;
    text-align: right;

    i {
      font-weight: 100;
    }
  }

  @media (max-width: 910px) {
    padding: 0 20px;

    &__links {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background: #f0f3f6;
      padding-top: 120px;

      &__close {
        display: block;
      }

      a {
        display: block;
        text-align: center;
        font-size: 22px;
      }
    }

    &__burger {
      display: block;
    }

    &__logo--big-center {
      max-width: 160px;
    }
  }
}