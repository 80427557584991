.contact-card {
  $this: ".contact-card";

  margin: -10px 0 50px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 15px 0 #00000026;

  &__panel {
    &--left {
      padding: 40px 50px 45px 45px;
    }

    &--right {
      padding: 40px 40px 45px 0;

      #{$this}__title {
        font-size: 17px;
        margin-bottom: 0;
        line-height: 32px;
      }

      #{$this}__text {
        color: #909090;
        font-size: 14px;
        line-height: 32px;
      }
    }
  }

  &__title {
    color: #000000;
    font-size: 31px;
    line-height: 52px;
    margin-bottom: 8px;
  }

  &__label {
    opacity: 0.7;
    color: #000000;
    font-size: 15px;
    line-height: 37px;
    margin-top: 15px;
  }

  &__textarea,
  &__input,
  &__select {
    box-shadow: none;
    border: none;
    background: #F8F8F8;
    transition: all 0.3s ease-in-out;
    height: 40px;
    color: #97A2B2;
    font-size: 14px;
    line-height: 32px;
    padding: 4px 13px;
    margin: 0;

    &:focus {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #000;
    }

    &.error {
      border-width: 0 0 2px 0;

      &:focus {
        border-width: 0 0 2px 0;
      }
    }

  }

  &__textarea {
    max-height: 140px;
  }

  &__select__holder {
      position: relative;

      &:after {
        content: '\f0d7';
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        line-height: 0;
        top: 50%;
        right: 13px;
        font-size: 12px;
        font-weight: bold;
        color: #626E7E;
      }
    }

  &__form {
    .medium-6 {
      &:nth-of-type(2n) {
        padding-left: 16px;
      }

      &:nth-of-type(2n - 1) {
        padding-right: 16px;
      }
    }
  }

  &__section {
    position: relative;
  }

  &__button {
    float: right;
    border-radius: 2px;
    background-color: #94BA0F;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.3px;
    line-height: 17px;
    padding: 14px 28px;
    cursor: pointer;
    margin-top: 15px;
  }

  label.error {
    margin: 5px 0;
  }

  &__textarea {
    &.error {
      border: solid 2px #E2493B;
    }
  }

  &__thank-you {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  &__icon {
    background: #83B00F;
    margin: 6px 10px 0 0;
    display: inline-block;
    line-height: 30px;
    width: 30px;
    color: white;
    border-radius: 15px;
    text-align: center;
  }

  @media (max-width: 1024px) {
    margin-top: 0;

    &__panel--left {
      padding: 16px 20px;
    }

    &__panel--right {
      padding: 35px 20px;
    }

    &__title {
      font-size: 18px;
      line-height: 26px;
    }

    &__form .cell {
      padding: 0!important;
    }

    &__button {
      font-size: 12.44px;
      letter-spacing: 1.16px;
      line-height: 15px;
      margin-top: 20px;
    }
  }
}
