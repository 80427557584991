.info {
  margin: -130px 0 50px;

  &__title {
    font-size: 30px;
    line-height: 37px;
    color: #94ba0f;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
  }

  &__form {
    margin: 0 -5% 50px;
    padding: 20px 40px;
    border-radius: 4px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.15);

    &__title {
      font-size: 20px;
      line-height: 60px;
      margin: 0;
      color: #626e7e;
      text-align: center;
    }

    &__submit {
      background: #fb8435;
      display: block;
      border-radius: 5px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 33px;
      color: #ffffff;
      font-weight: 800;
      text-align: center;
      box-shadow: 0 1px 2px rgba(0,0,0,0.3);
      margin: 0 auto;
      padding: 10px 50px 10px 30px;
      background-image: url("/static/images/arrow-right-white.png");
      background-repeat: no-repeat;
      background-position: calc(100% - 15px);
    }

    &--space {
      margin: 10px 0;
    }
  }

  &__point {
    padding: 0 0 0 10%;
    margin-bottom: 30px;

    &:nth-of-type(2) {
      padding: 0 10% 0 0;
    }

    h4 {
      font-size: 22px;
      line-height: 37px;
      color: #272f3a;
    }

    p {
      font-size: 14px;
      line-height: 30px;
      color: #97a2b2;
    }
  }

  &__logos {
    text-align: center;
    margin: 40px auto;

    &__disclaimer {
      color: #272F3A;
      font-size: 11px;
      font-weight: 300;
      margin: 40px 0;
    }

    .cell {
      position: relative;
      height: 70px;
    }

    img {
      max-width: 128px;
      max-height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--small {
      max-height: 60px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 0;

    &__title {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 28px;
    }

    &__form {
      &--space {
        margin: 5px 0;
      }
    }

    &__point {
      text-align: center;
      padding: 0;

      img {
        width: 60px;
        display: block;
        margin: 0 auto 20px;
      }

      h4 {
        font-size: 16px;
        line-height: 28px;
      }

      p {
        line-height: 28px;
      }
    }

    &__logos {
      img {
        max-width: 80px;
        max-height: 60px;
      }

      .cell {
        height: 60px;
      }
    }
  }
}
