body, a, span, div, li, label, p,
h1, h2, h3, h4, h5, h6,
button, input, textarea, select {
  font-family: 'Montserrat', sans-serif;
  color: #5c5c5c;
}

.wrap {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

  &--thin {
    max-width: 1100px;
  }
}

.loader {
  height: 24px;
  width: 24px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: spin 1.2s infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {transform: rotate(0deg); }
  100% {transform: rotate(360deg); }
}
