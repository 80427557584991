.quote-form {
  input[type="text"] {
    width: calc(100% - 32px);
    border: none;
    padding: 16px 15px;
    border-radius: 0 3px 3px 0;
    float: right;
    font-size: 16px;
    color: #272f3a;
    font-weight: 400;
    margin: 0;
    height: 50px;
    box-shadow: none;

    &[data-upper] {
      text-transform: uppercase;

      &:placeholder-shown {
        text-transform: none;
      }
    }

    &::placeholder {
      color: #3f3f3f;
    }
  }

  &__registration {
    background: #ffcb00;
  }

  &__postcode {
    background: #f8d3da;
  }

  &__side {
    padding: 5px;
    float: left;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 13px;
    border-radius: 5px 0 0 5px;
    width: 32px;
    height: 50px;

    &--registration {
      background: #223e90;
    }

    &--postcode {
      background: #df2c4b;
    }

    p {
      margin: 3px 0 0;
      font-size: 12px;
      letter-spacing: 1px;
      color: #ffce00;
      font-weight: 700;
    }
  }

  &--small {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 lightgrey;
    padding: 36px;
    font-size: 0;

    &__text {
      width: 35%;
      font-size: 18px;
      display: inline-block;
      padding-right: 10px;
      vertical-align: middle;
    }

    &__inputs {
      display: inline-block;
      width: 65%;
      vertical-align: middle;
    }

    &__input-holder {
      width: 30%;
      vertical-align: middle;
      display: inline-block;
      padding: 0 5px;
    }

    &__cta-holder {
      width: 40%;
      display: inline-block;
      vertical-align: middle;
      padding: 0 5px;

      a {
        box-shadow: none;
        margin: 0;
        padding: 10px 20px;
      }
    }
  }

  @media (max-width: 1240px) {
    &--small {
      &__inputs {
        text-align: center;
      }

      &__input-holder {
        width: 50%;
        max-width: 250px;
      }

      &__cta-holder {
        width: 80%;
        max-width: 300px;
        display: table;
        margin: 10px auto 0;
      }
    }
  }

  @media (max-width: 1024px) {
    &--small {
      padding: 12px 16px 32px;
      text-align: center;

      &__text, &__inputs, &__input-holder, &__cta-holder {
        display: table;
        width: 100%;
        margin: 10px auto 0;
      }

      &__text {
        font-size: 15px;
        line-height: 30px;
        margin-top: 0;
      }

      &__inputs {
        margin-top: 0;
      }

      &__input-holder {
        max-width: 300px;
      }

      &__cta-holder {
        max-width: 270px;
      }
    }
  }
}
