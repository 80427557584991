.three-steps {
  &__card {
    background: #ffffff;
    text-align: center;
    padding: 30px 20px 40px;
    box-shadow: 0 0 10px 0 #e2e2e2;
    position: relative;
    margin: 20px auto;
    height: 300px;

    &__number {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      top: -20px;
      left: calc(50% - 20px);
      background: #94ba0f;
      border-radius: 50%;
      color: #ffffff;
      font-size: 20px;
    }

    &__image {
      height: 180px;
      max-width: 100%;

      &--smaller {
        margin: 30px 0;
        height: 110px;
      }

      &--bumped {
        transform: translateX(-10px);
      }
    }

    h4 {
      color: #94ba0f;
      font-weight: 600;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      color: #9a9a9a;
    }
  }

  @media (max-width: 1024px) {
    &__card {
      height: 270px;
    }
  }
}