.section {
  &__fade {
    width: 100%;
    height: 100px;
    background-image: url("/static/images/background-top-fade.png");
    background-size: contain;
    margin-bottom: -1px;

    &--reverse {
      transform: rotate(180deg);
      margin-bottom: -99px;
    }
  }

  @media (max-width: 640px) {
    &__fade {
      display: none;
    }
  }
}